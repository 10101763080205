import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';

const HSRGuidesGameSystemsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Game systems</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_gamesystems.png"
            alt="Game systems"
          />
        </div>
        <div className="page-details">
          <h1>Game systems</h1>
          <h2>
            Information about various systems available in Honkai: Star Rail.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Trailblaze Level" />
        <StaticImage
          src="../../../images/starrail/generic/pompom.webp"
          alt="Pom-Pom"
        />
        <p>
          Trailblaze Level is your overall account level and can be increased by
          obtaining Trailblaze EXP. Trailblaze EXP can primarily be earned by
          completing in-game missions and events as well as spending Trailblaze
          Power (the game’s stamina system).
        </p>
        <p>
          As your Trailblaze Level increases, you will unlock more game
          functions such as Training Missions (daily missions) as well as
          increasing the number of times that you can ascend your Characters,
          increasing their level cap. Additionally, the difficulty of the enemy
          in the overworld will also increase once you reach certain Trailblaze
          Level milestones. <strong>The maximum Trailblaze level is 70.</strong>
        </p>
        <p>
          As Trailblaze Level increases, the Equilibrium Level also increases,
          making enemies more challenging while also increasing rewards.
          Equilibrium Level increases at Level 20, 30, 40, 50, 60, and 65 after
          completing the related Adventure Mission.
        </p>
        <p>
          Everytime that your Trailblaze Level increases, you can obtain rewards
          from Pom-Pom at the Astral Express (visible on the image above).
        </p>
        <SectionHeader title="Trailblaze Power" />
        <StaticImage
          src="../../../images/starrail/generic/guide_stamina.webp"
          alt="Guide"
        />
        <p>
          Trailblaze Power is the stamina system of Honkai: Star Rail. It is
          primarily used to obtain resources and upgrade materials from the
          various resource and boss dungeons in the game.
        </p>
        <p>
          Trailblaze Power is generated at a rate of 1 per 6 minutes. You can
          store up to a maximum of 180. A total of 240 Trailblaze Power can be
          generated every day.
        </p>
        <p>
          You can also replenish your Trailblaze power by using Fuel (reward
          from various missions) or Stellar Jades). Fuel is an in game
          consumable that when used will give you 60 Trailblaze Power
          immediately.
        </p>
        <SectionHeader title="Missions" />
        <StaticImage
          src="../../../images/starrail/generic/guide_dailytraining.webp"
          alt="Guide"
        />
        <p>
          Missions in Honkai: Star Rail come in a couple of different variety:
        </p>
        <ul>
          <li>Trailblaze</li>
          <ul>
            <li>
              Trailblaze missions are the main story missions. They are denoted
              with an orange color in your quest log and on the mini map.
            </li>
            <li>
              They reward Stellar Jades, the game’s gacha currency and are
              required for account progression as completing them is the only
              way to unlock new areas and regions.
            </li>
          </ul>
          <li>Companion</li>
          <ul>
            <li>
              Companion Missions are side missions for specific Characters.
              These are generally missions that will provide you with more
              background and lore on the Character that they are centered on.
              They are denoted with a purple color in your quest log and on the
              min map.{' '}
            </li>
            <li>
              They reward Stellar Jades and is a decent amount of Trailblaze EXP
              as well, making them a good source of obtaining EXP to level your
              Trialblaze Level early on.{' '}
            </li>
          </ul>
          <li>Adventure</li>
          <ul>
            <li>
              Adventure missions are side missions. They are denoted with a blue
              color in your quest log and on the mini map. Some will reward
              Stellar Jades.
            </li>
          </ul>
          <li>Training</li>
          <ul>
            <li>Training missions are your daily missions.</li>
            <li>
              You will receive a semi-randomized set of 6 Training missions
              every day.
            </li>
            <li>
              Each Training mission will provide you with 100 or 200 Activity
              Points and you need to gather 500 in total to obtain all daily
              rewards which includes a total of 60 Stellar Jades as well as some
              upgrade materials:
            </li>
          </ul>
        </ul>
        <SectionHeader title="Operation Briefing" />
        <StaticImage
          src="../../../images/starrail/generic/guide_operationbrief.webp"
          alt="Guide"
        />
        <p>
          Operation Briefing is a set of beginner missions that are designed to
          guide the player’s progression at the start of the game. Completing
          these missions will reward you with Stellar Jades , Warp Passes,
          Relics, Light Cones and other good stuff.
        </p>
        <p>There are 9 pages of beginner missions in the Operation Briefing.</p>
        <SectionHeader title="Achievements" />
        <StaticImage
          src="../../../images/starrail/generic/guide_achie.webp"
          alt="Guide"
        />
        <p>
          As the name suggests, Achievements are a collection of tasks that the
          player can complete to earn a varying amount of Stellar Jades.
        </p>
        <SectionHeader title="Assignments" />
        <StaticImage
          src="../../../images/starrail/generic/guide_assignment.webp"
          alt="Guide"
        />
        <p>
          Assignments are passive activities that the player can assign their
          Characters to do. While on Assignments, Characters will passively
          gather resources and materials for the player. They are the equivalent
          of expeditions in other gacha games.
        </p>
        <p>
          The player can send up to 4 sets of 2 Characters (8 total) out on
          Assignments at any given time. Each Assignment has a duration of 4, 8,
          12, or 20 hours. Once completed, the Characters will bring back the
          resources and materials listed in the reward section of the
          Assignment.
        </p>
        <p>
          Also, you can still use the characters you've sent on Assignment, so
          don't be afraid about losing access to them for the duration they're
          gone.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesGameSystemsPage;

export const Head: React.FC = () => (
  <Seo
    title="Game systems | Honkai: Star Rail | Prydwen Institute"
    description="Information about various systems available in Honkai: Star Rail."
  />
);
